import { CmsSite, CmsSiteData, UserDocument } from '@taltta/cms-data-models';
import { useMemo } from 'react';
import {
    Form,
    H2,
    FieldGroup,
    Label,
    TextInput,
    FieldGroupError,
    Select,
    Button,
} from '@taltta/react-elements';
import { Controller, useForm } from 'react-hook-form';

export type EditSiteForm = Pick<CmsSiteData, 'ownerId'>;

type EditSiteFormProps = {
    onSubmit: (data: EditSiteForm) => Promise<void>;
    users: UserDocument[];
    initialValues: CmsSite;
};

export function EditSiteForm(props: EditSiteFormProps) {
    const options = useMemo(() => {
        return props.users.map((u) => ({
            label: u.email,
            value: u._id,
        }));
    }, [props.users]);
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<EditSiteForm>({
        values: {
            ownerId: props.initialValues.ownerId,
        },
    });

    return (
        <Form onSubmit={handleSubmit((data) => props.onSubmit(data))}>
            <H2>Edit site</H2>
            <FieldGroup>
                <Label>Owner</Label>
                <Controller
                    name="ownerId"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Owner is required',
                        },
                    }}
                    render={({ field }) => (
                        <Select
                            options={options}
                            value={options.find(
                                (op) => op.value === field.value
                            )}
                            onChange={(
                                op: {
                                    label: string;
                                    value: string;
                                } | null
                            ) => field.onChange(op?.value)}
                        />
                    )}
                />
                {errors.ownerId && (
                    <FieldGroupError>{errors.ownerId.message}</FieldGroupError>
                )}
            </FieldGroup>
            <Button type="submit">Save changes</Button>
        </Form>
    );
}
