import { useMemo } from 'react';
import isFunction from 'lodash/isFunction';

export function useSSRSafeFunction<
    A extends unknown[],
    R,
    O extends OnFail<Z, R> = 'nothing',
    Z extends unknown[] = A
>(fn: AnyFn<A, R>, opts: { onFail?: O } = {}) {
    const { onFail } = opts;
    const safeF = useMemo((): AnyFn<A, R> | ReturnValue<O, Z, R> => {
        if (typeof window !== 'undefined') {
            return fn;
        }
        if (isFunction(onFail)) {
            return onFail;
        }
        switch (onFail) {
            case 'throw':
                return () => {
                    throw new Error('Client side function ran inside server');
                };

            case 'nothing':
            default:
                return (() => {}) as ReturnValue<O, Z, R>;
        }
    }, [onFail, fn]);

    return safeF;
}

type SimpleFn = AnyFn<any, void>;

type NothingFail = SimpleFn;
type ThrowFail = SimpleFn;
type AnyFn<T extends unknown[], R> = (...args: T) => R;

type OnFail<Z extends unknown[], T> = 'throw' | 'nothing' | AnyFn<Z, T>;

type ReturnValue<
    O extends OnFail<Z, T>,
    Z extends unknown[],
    T
> = O extends 'nothing'
    ? NothingFail
    : O extends 'throw'
    ? ThrowFail
    : O extends AnyFn<Z, T>
    ? AnyFn<Z, T>
    : never;
