import clsx from 'clsx';
import React, {
    ComponentType,
    forwardRef,
    useEffect,
    useMemo,
    useState,
} from 'react';
import styled, {
    FlattenInterpolation,
    ThemedStyledProps,
    DefaultTheme,
} from 'styled-components';

export const Stylable = <T extends { component: React.ReactElement } = any>(
    props: T
) => {
    return;
};

export const makeStyledElement = <
    P extends object = {},
    E extends { className?: string } = any,
    T extends object = DefaultTheme,
    C extends object = object
>(
    css: FlattenInterpolation<ThemedStyledProps<C, T>>
) => {
    return styled<
        ComponentType<
            {
                component: React.ReactElement<E>;
                className?: string;
                innerRef?: any;
            } & C &
                P
        >
    >(({ component, className, innerRef, ...props }) => {
        let children: React.ReactNode[] | undefined;
        if ('children' in component.props) {
            children = [
                ...(children ?? []),
                component.props.children as React.ReactNode,
            ];
        }
        if ('children' in props) {
            children = [...(children ?? []), props.children as React.ReactNode];
        }
        return React.cloneElement(
            component,
            {
                ...component.props,
                ...props,
                ref: innerRef,
                className: clsx(component.props.className, className),
            },
            children
        );
    })`
        ${css}
    `;
};

export const childToComponent = <T extends object = any>(
    c: React.ReactElement,
    props?: T
) => {
    return React.cloneElement(c, { ...c.props, ...props });
};
