import { Switch, Route } from 'wouter';
import { AdminIndexPage } from './pages/admin/AdminIndexPage';
import { AdminUsersPage } from './pages/admin/AdminUsersPage';
import { LoginPage } from './pages/auth/LoginPage';
import { AdminRouteGuard } from './components/guards/AdminRouteGuard';
import { IndexPage } from './pages/IndexPage';
import { ComponentType } from 'react';
import { BaseGuard, Guard } from './components/guards/BaseGuard';

const routes: {
    path: string;
    Page: ComponentType<any>;
}[] = [
    {
        path: '/auth/login',
        Page: LoginPage,
    },
    {
        path: '/admin/users',
        Page: AdminRouteGuard(AdminUsersPage),
    },
    {
        path: '/admin',
        Page: AdminRouteGuard(AdminIndexPage),
    },
    {
        path: '/',
        Page: AdminRouteGuard(IndexPage),
    },
];

export function App() {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route key={`${route.path}-${index}`} path={route.path}>
                    {(params) => <route.Page urlParams={params} />}
                </Route>
            ))}
            <Route>
                <p>404</p>
            </Route>
        </Switch>
    );
}
