import React, {
    Children,
    isValidElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useStoredState } from '../hooks/useStoredState';
import { HiChevronDown } from 'react-icons/hi2';
import styled, { css } from 'styled-components';

import { childToComponent, makeStyledElement } from './styled-element';

export const Accordion = (props: {
    children?: React.ReactNode;
    storeKey?: string;
    initialOpen?: boolean;
}) => {
    const [open, setOpen] = useStoredState(
        props.initialOpen ?? false,
        props.storeKey
    );

    const contentRef = useRef<HTMLElement>();

    const [contentMaxHeight, setContentMaxHeight] = useState<
        number | undefined
    >(undefined);

    const transitionSeconds = 0.5;
    const [contentOverflow, setContentOverflow] = useState(() => {
        if (open) {
            return 'auto';
        }

        return 'hidden';
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            setContentOverflow(() => {
                if (open) {
                    return 'auto';
                }

                return 'hidden';
            });
        }, transitionSeconds * 1000);
        if (!open) {
            clearTimeout(timer);
            setContentOverflow('hidden');
        }
        return () => clearTimeout(timer);
    }, [open, setContentOverflow]);

    useEffect(() => {
        if (contentRef.current) {
            setContentMaxHeight(contentRef.current.scrollHeight);
        }
    });
    const { children } = props;
    const findComponentByRole = useCallback(
        (key: string) => {
            let component: any;
            Children.forEach(children, (child) => {
                if (!isValidElement(child)) {
                    return;
                }
                if ('role' in child.props && child.props.role === key) {
                    component = child;
                }
            });
            return component;
        },
        [children]
    );

    const headingElem = findComponentByRole('heading');
    const contentElem = findComponentByRole('content');

    return (
        <>
            {headingElem && (
                <AccordionHeading
                    component={childToComponent(headingElem)}
                    onClick={(e: any) => {
                        headingElem?.props?.onClick?.(e);
                        setOpen((o) => !o);
                    }}
                >
                    <AccordionChevron
                        transitionSeconds={transitionSeconds}
                        open={open}
                    >
                        <HiChevronDown />
                    </AccordionChevron>
                </AccordionHeading>
            )}
            {contentElem && (
                <AccordionContent
                    component={childToComponent(contentElem)}
                    $open={open}
                    $maxHeight={contentMaxHeight}
                    $transitionSeconds={transitionSeconds}
                    innerRef={contentRef}
                    $contentOverFlow={contentOverflow}
                />
            )}
        </>
    );
};

const accordionHeadingCss = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
`;

const AccordionHeading = makeStyledElement<{
    children: React.ReactNode;
    onClick: (e: any) => void;
}>(accordionHeadingCss);

const accordionContentCss = css<{
    $open: boolean;
    $maxHeight: number | undefined;
    $transitionSeconds: number;
    $contentOverFlow: string;
}>`
    overflow: ${(props) =>
        props.$maxHeight ? props.$contentOverFlow : 'hidden'};
    height: ${(props) => (props.$open ? `${props.$maxHeight}px` : '0')};
    transition-property: ${(props) => (props.$maxHeight ? 'height' : '')};
    transition-duration: ${(props) => `${props.$transitionSeconds}s`};
    transition-timing-function: ease-in-out;
    box-sizing: border-box;
`;

const AccordionContent = makeStyledElement(accordionContentCss);

const AccordionChevron = styled.span<{
    open: boolean;
    transitionSeconds: number;
}>`
    transition: transform ${(props) => `${props.transitionSeconds}s`}
        ease-in-out;
    transform: ${(props) => (props.open ? 'rotate(180deg)' : '')};
`;
