import { useCallback, useEffect, useState } from 'react';
import isFunction from 'lodash/isFunction';
import { useSSRSafeFunction } from './useSSRSafeFunction';

export function useStoredState<S>(initialState: S | (() => S), key?: string) {
    const initState = useCallback(() => {
        if (isFunction(initialState)) {
            return initialState();
        }

        return initialState;
    }, [initialState]);

    const getStoredValue = useSSRSafeFunction(
        useCallback(() => {
            try {
                if (!key) {
                    throw new Error('no key');
                }
                const value = window.localStorage.getItem(key);
                if (!value) {
                    throw new Error('no value');
                }
                return JSON.parse(value) as S;
            } catch (e) {
                return initState();
            }
        }, [initState, key]),
        { onFail: initState }
    );

    const storeValue = useSSRSafeFunction(
        useCallback(
            (value: S) => {
                try {
                    if (key) {
                        window.localStorage.setItem(key, JSON.stringify(value));
                    }
                } catch (e) {}
            },
            [key]
        )
    );

    const [state, setState] = useState(getStoredValue);

    useEffect(() => {
        storeValue(state);
    }, [state, storeValue]);

    return [state, setState] as const;
}
