import { Link, useLocation } from 'wouter';
import clsx from 'clsx';
import { useUser } from '../providers/AuthProvider';
import { useEffect, useMemo, useState } from 'react';
import { Button } from '@taltta/react-elements';
import React from 'react';
export type AppContainerNavItem = {
    text: string;
    href: string;
    active: boolean;
};

export type AppContainerProps = {
    children: React.ReactNode;
};

const navWidth = 200;
const topbarHeight = 64;

export function AppContainer({ children }: AppContainerProps) {
    const { logout, isAdmin } = useUser();
    const [location] = useLocation();

    const topbarItems = useMemo(() => {
        return [
            {
                key: 'logout',
                content: (
                    <Button size="small" variant="secondary" onClick={logout}>
                        Logout
                    </Button>
                ),
            },
        ];
    }, [logout, isAdmin]);

    const navItems = useMemo<AppContainerNavItem[]>(() => {
        const adminNavItems: Omit<AppContainerNavItem, 'active'>[] = [];
        if (isAdmin) {
            adminNavItems.push(
                ...[
                    { href: '/admin', text: 'Websites' },
                    { href: '/admin/users', text: 'Users' },
                ]
            );
        }
        return [...adminNavItems].map((item) => ({
            ...item,
            active: location === item.href,
        }));
    }, [location, isAdmin]);

    return (
        <div>
            <nav
                id="topbar"
                style={{ height: `${topbarHeight}px`, left: `${navWidth}px` }}
                className="fixed top-0 right-0 flex gap-4 justify-end items-center px-8 py-2"
            >
                {topbarItems.map((item) => (
                    <React.Fragment key={item.key}>
                        {item.content}
                    </React.Fragment>
                ))}
            </nav>
            <nav
                id="sidenav"
                style={{ width: navWidth }}
                className="fixed h-screen bg-cyan-900 flex flex-col p-4"
            >
                <div className="pb-12 text-white">APP LOGO</div>
                <div className="flex flex-col gap-4">
                    {navItems.map((item) => (
                        <Link
                            key={item.href + item.text}
                            href={item.href}
                            className={clsx(
                                item.active ? 'text-cyan-600' : 'text-white'
                            )}
                        >
                            {item.text}
                        </Link>
                    ))}
                </div>
            </nav>
            <div
                style={{
                    paddingLeft: navWidth,
                    paddingTop: `${topbarHeight}px`,
                }}
                className="flex flex-col w-screen min-h-screen"
            >
                <main className="bg-gray-100 flex-grow p-4">{children}</main>
            </div>
        </div>
    );
}
