const palette = [
    {
        paletteName: 'gray',
        swatches: [
            {
                name: '100',
                color: '#f7fafc',
            },
            {
                name: '200',
                color: '#edf2f7',
            },
            {
                name: '300',
                color: '#e2e8f0',
            },
            {
                name: '400',
                color: '#cbd5e0',
            },
            {
                name: '500',
                color: '#a0aec0',
            },
            {
                name: '600',
                color: '#718096',
            },
            {
                name: '700',
                color: '#4a5568',
            },
            {
                name: '800',
                color: '#2d3748',
            },
            {
                name: '900',
                color: '#1a202c',
            },
        ] as const,
    },
    {
        paletteName: 'red',
        swatches: [
            {
                name: '100',
                color: '#fff5f5',
            },
            {
                name: '200',
                color: '#fed7d7',
            },
            {
                name: '300',
                color: '#feb2b2',
            },
            {
                name: '400',
                color: '#fc8181',
            },
            {
                name: '500',
                color: '#f56565',
            },
            {
                name: '600',
                color: '#e53e3e',
            },
            {
                name: '700',
                color: '#c53030',
            },
            {
                name: '800',
                color: '#9b2c2c',
            },
            {
                name: '900',
                color: '#742a2a',
            },
        ] as const,
    },
    {
        paletteName: 'orange',
        swatches: [
            {
                name: '100',
                color: '#fffaf0',
            },
            {
                name: '200',
                color: '#feebc8',
            },
            {
                name: '300',
                color: '#fbd38d',
            },
            {
                name: '400',
                color: '#f6ad55',
            },
            {
                name: '500',
                color: '#ed8936',
            },
            {
                name: '600',
                color: '#dd6b20',
            },
            {
                name: '700',
                color: '#c05621',
            },
            {
                name: '800',
                color: '#9c4221',
            },
            {
                name: '900',
                color: '#7b341e',
            },
        ] as const,
    },
    {
        paletteName: 'yellow',
        swatches: [
            {
                name: '100',
                color: '#fffff0',
            },
            {
                name: '200',
                color: '#fefcbf',
            },
            {
                name: '300',
                color: '#faf089',
            },
            {
                name: '400',
                color: '#f6e05e',
            },
            {
                name: '500',
                color: '#ecc94b',
            },
            {
                name: '600',
                color: '#d69e2e',
            },
            {
                name: '700',
                color: '#b7791f',
            },
            {
                name: '800',
                color: '#975a16',
            },
            {
                name: '900',
                color: '#744210',
            },
        ] as const,
    },
    {
        paletteName: 'green',
        swatches: [
            {
                name: '100',
                color: '#f0fff4',
            },
            {
                name: '200',
                color: '#c6f6d5',
            },
            {
                name: '300',
                color: '#9ae6b4',
            },
            {
                name: '400',
                color: '#68d391',
            },
            {
                name: '500',
                color: '#48bb78',
            },
            {
                name: '600',
                color: '#38a169',
            },
            {
                name: '700',
                color: '#2f855a',
            },
            {
                name: '800',
                color: '#276749',
            },
            {
                name: '900',
                color: '#22543d',
            },
        ] as const,
    },
    {
        paletteName: 'teal',
        swatches: [
            {
                name: '100',
                color: '#e6fffa',
            },
            {
                name: '200',
                color: '#b2f5ea',
            },
            {
                name: '300',
                color: '#81e6d9',
            },
            {
                name: '400',
                color: '#4fd1c5',
            },
            {
                name: '500',
                color: '#38b2ac',
            },
            {
                name: '600',
                color: '#319795',
            },
            {
                name: '700',
                color: '#2c7a7b',
            },
            {
                name: '800',
                color: '#285e61',
            },
            {
                name: '900',
                color: '#234e52',
            },
        ] as const,
    },
    {
        paletteName: 'blue',
        swatches: [
            {
                name: '100',
                color: '#ebf8ff',
            },
            {
                name: '200',
                color: '#bee3f8',
            },
            {
                name: '300',
                color: '#90cdf4',
            },
            {
                name: '400',
                color: '#63b3ed',
            },
            {
                name: '500',
                color: '#4299e1',
            },
            {
                name: '600',
                color: '#3182ce',
            },
            {
                name: '700',
                color: '#2b6cb0',
            },
            {
                name: '800',
                color: '#2c5282',
            },
            {
                name: '900',
                color: '#2a4365',
            },
        ] as const,
    },
    {
        paletteName: 'indigo',
        swatches: [
            {
                name: '100',
                color: '#ebf4ff',
            },
            {
                name: '200',
                color: '#c3dafe',
            },
            {
                name: '300',
                color: '#a3bffa',
            },
            {
                name: '400',
                color: '#7f9cf5',
            },
            {
                name: '500',
                color: '#667eea',
            },
            {
                name: '600',
                color: '#5a67d8',
            },
            {
                name: '700',
                color: '#4c51bf',
            },
            {
                name: '800',
                color: '#434190',
            },
            {
                name: '900',
                color: '#3c366b',
            },
        ] as const,
    },
    {
        paletteName: 'purple',
        swatches: [
            {
                name: '100',
                color: '#faf5ff',
            },
            {
                name: '200',
                color: '#e9d8fd',
            },
            {
                name: '300',
                color: '#d6bcfa',
            },
            {
                name: '400',
                color: '#b794f4',
            },
            {
                name: '500',
                color: '#9f7aea',
            },
            {
                name: '600',
                color: '#805ad5',
            },
            {
                name: '700',
                color: '#6b46c1',
            },
            {
                name: '800',
                color: '#553c9a',
            },
            {
                name: '900',
                color: '#44337a',
            },
        ] as const,
    },
    {
        paletteName: 'pink',
        swatches: [
            {
                name: '100',
                color: '#fff5f7',
            },
            {
                name: '200',
                color: '#fed7e2',
            },
            {
                name: '300',
                color: '#fbb6ce',
            },
            {
                name: '400',
                color: '#f687b3',
            },
            {
                name: '500',
                color: '#ed64a6',
            },
            {
                name: '600',
                color: '#d53f8c',
            },
            {
                name: '700',
                color: '#b83280',
            },
            {
                name: '800',
                color: '#97266d',
            },
            {
                name: '900',
                color: '#702459',
            },
        ] as const,
    },
] as const;

export function getColor(
    color: typeof palette[number]['paletteName'],
    variant: typeof palette[number]['swatches'][number]['name']
): string {
    const p = palette.find((p) => p.paletteName === color);
    if (!p) {
        throw new Error(`Color: ${color} not found`);
    }
    const s = (
        p?.swatches as unknown as { name: string; color: string }[]
    ).find((s) => s.name === variant);
    if (!s) {
        throw new Error(`Variant: ${variant} not found`);
    }
    return s.color;
}
