import { JwtData } from '@taltta/cms-data-models';
import { createContext, useContext, useEffect, useMemo } from 'react';
import useSwr from 'swr';
import { useLocation } from 'wouter';
import axios from 'axios';

export type AuthContext = {
    user?: JwtData;
    loadingUser: boolean;
    loggedIn: boolean;
    isAdmin: boolean;
    login: (email: string, password: string) => Promise<void>;
    register: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
};

const authContext = createContext<AuthContext>(null as any);

export function AuthProvider({ children }) {
    const {
        data: user,
        isLoading: isLoadingUser,
        mutate: mutateUser,
    } = useSwr<JwtData>(
        '/api/auth/me',
        async (url) => {
            await axios.post('/api/auth/refresh', {});
            const res = await axios.get(url);
            return res.data;
        },
        { shouldRetryOnError: false }
    );

    const [location] = useLocation();

    const login = async (email: string, password: string) => {
        await axios.post('/api/auth/login', { email, password });
        await mutateUser();
    };

    const register = async (email: string, password: string) => {
        await axios.post('/api/auth/register', { email, password });
        await mutateUser();
    };

    const logout = async () => {
        await axios.post('/api/auth/logout');
        await mutateUser(undefined, { revalidate: false });
    };

    // Refresh always on location change
    useEffect(() => {
        if (user && !isLoadingUser) {
            const refresh = async () => {
                await fetch('/api/auth/refresh', { method: 'POST' });
                await mutateUser();
            };
            refresh();
        }
    }, [location, mutateUser]);

    const isAdmin = useMemo(() => {
        return user?.isAdmin ?? false;
    }, [user]);

    const loggedIn = useMemo(() => {
        return !!user;
    }, [user]);

    return (
        <authContext.Provider
            value={{
                user,
                loadingUser: isLoadingUser,
                isAdmin,
                loggedIn,
                login,
                register,
                logout,
            }}
        >
            {children}
        </authContext.Provider>
    );
}

export function useUser() {
    return useContext(authContext);
}
