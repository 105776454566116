import { UserDocument } from '@taltta/cms-data-models';
import useSwr from 'swr';
import { useState } from 'react';
import { Button, Card, H3, Modal } from '@taltta/react-elements';
import { CreateUserForm } from '../../components/CreateUserForm';
import axios from 'axios';
import { axiosWrapper } from '../../utils/axios-wrapper';
export function AdminUsersPage() {
    const { data: usersData, mutate: mutateUsers } =
        useSwr<UserDocument[]>('/api/admin/users');
    const [creatingNewUser, setCreatingNewUser] = useState(false);
    return (
        <div className="flex flex-col gap-4">
            {creatingNewUser && (
                <Modal onCloseCallback={() => setCreatingNewUser(false)}>
                    <CreateUserForm
                        onSubmit={async (data) => {
                            await axiosWrapper(async () => {
                                await axios.post('/api/admin/users', data);
                                await mutateUsers();
                                setCreatingNewUser(false);
                            });
                        }}
                    />
                </Modal>
            )}
            <div>
                <Button onClick={() => setCreatingNewUser(true)}>
                    Add user
                </Button>
            </div>
            <div className="grid grid-cols-3 gap-4">
                {usersData
                    ?.filter((user) => !!user.email)
                    ?.map((user) => (
                        <Card key={user._id} className="flex flex-col gap-4">
                            <H3>{user.email}</H3>
                            {user.isAdmin ? (
                                <H3>Admin user</H3>
                            ) : (
                                <H3>Customer user</H3>
                            )}
                            <Button onClick={() => console.log('TODO')}>
                                Delete user
                            </Button>
                        </Card>
                    ))}
            </div>
        </div>
    );
}
