import { useEffect, useState } from 'react';

import { AppLayout } from '../../components/AppLayout';
import useSwr from 'swr';
import { CmsSite, UserDocument } from '@taltta/cms-data-models';
import {
    Button,
    Card,
    Form,
    H3,
    Modal,
    TextInput,
} from '@taltta/react-elements';

import axios from 'axios';
import { CreateSiteForm } from '../../components/CreateSiteForm';
import { EditSiteForm } from '../../components/EditSiteForm';
import { axiosWrapper } from '../../utils/axios-wrapper';

export function AdminIndexPage() {
    const { data: sitesData, mutate: mutateSites } =
        useSwr<CmsSite[]>('/api/sites');
    const { data: usersData } = useSwr<UserDocument[]>('/api/admin/users');
    const [creatingNewSite, setCreatingNewSite] = useState(false);
    const [editingSite, setEditingSite] = useState<CmsSite>();
    return (
        <div className="flex flex-col gap-4">
            {usersData && creatingNewSite && (
                <Modal onCloseCallback={() => setCreatingNewSite(false)}>
                    <CreateSiteForm
                        onSubmit={async (data) => {
                            await axiosWrapper(async () => {
                                await axios.post('/api/sites', data);
                                await mutateSites();
                                setCreatingNewSite(false);
                            });
                        }}
                        users={usersData}
                    />
                </Modal>
            )}
            {usersData && editingSite && (
                <Modal onCloseCallback={() => setEditingSite(undefined)}>
                    <EditSiteForm
                        initialValues={editingSite}
                        onSubmit={async (data) => {
                            await axiosWrapper(async () => {
                                await axios.put(
                                    `/api/sites/${editingSite.key}`,
                                    data
                                );
                                await mutateSites();
                                setEditingSite(undefined);
                            });
                        }}
                        users={usersData}
                    />
                </Modal>
            )}
            <div>
                <Button onClick={() => setCreatingNewSite(true)}>
                    Create a site
                </Button>
            </div>

            <div className="grid grid-cols-3 gap-4">
                {sitesData
                    ?.filter((site) => !!site.key)
                    ?.map((site) => (
                        <Card
                            key={site._id as string}
                            className="flex flex-col gap-4"
                        >
                            <H3>{site.key}</H3>
                            <H3>
                                Owner:{' '}
                                {
                                    usersData?.find(
                                        (u) => u._id === site.ownerId
                                    )?.email
                                }
                            </H3>
                            <Button onClick={() => setEditingSite(site)}>
                                Edit
                            </Button>
                            <Button onClick={() => console.log('TODO')}>
                                Delete site
                            </Button>
                        </Card>
                    ))}
            </div>
        </div>
    );
}
