import styled from 'styled-components';
import { Card } from './card';
import { getColor } from '../colors/colors';
import ReactDOM from 'react-dom';

const ModalWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    z-index: 9997;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
`;

const ModalOverlay = styled.div`
    opacity: 0.45;
    background-color: ${getColor('gray', '700')};
    z-index: 9998;
    position: absolute;
    overflow: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const ModalContent = styled.div`
    z-index: 9999;
    width: 80%;
    height: 80%;
    background-color: transparent;
`;

type ModalProps = {
    children?: React.ReactNode;
    onCloseCallback: () => void;
};

export function Modal(props: ModalProps): React.ReactPortal {
    return ReactDOM.createPortal(
        <ModalWrapper>
            <ModalOverlay onClick={props.onCloseCallback} />
            <ModalContent>
                <Card
                    style={{
                        position: 'relative',
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'white',
                    }}
                >
                    <button
                        onClick={props.onCloseCallback}
                        style={{
                            position: 'absolute',
                            top: '1rem',
                            right: '1rem',
                        }}
                    >
                        X
                    </button>
                    {props.children}
                </Card>
            </ModalContent>
        </ModalWrapper>,
        document.body
    );
}
