import styled from 'styled-components';
import { getColor } from '../colors/colors';

type ButtonVariant = 'primary' | 'secondary';
type ButtonSize = 'small' | 'normal';

type ButtonProps = {
    variant?: ButtonVariant;
    size?: ButtonSize;
};

export const Button = styled.button<ButtonProps>`
    color: ${(props) =>
        props.variant === 'secondary' ? getColor('gray', '900') : 'white'};
    background-color: ${(props) => chooseBgColor(props.variant)};
    border: ${(props) =>
        props.variant === 'secondary'
            ? `1px solid ${getColor('gray', '300')}`
            : 'none'};
    padding: ${(props) => choosePadding(props.size)};
    border-radius: 12px;
    font-size: ${(props) => chooseFontsize(props.size)};
    cursor: pointer;

    &:hover {
        background-color: ${(props) => chooseBgColor(props.variant, true)};
    }

    &:disabled {
        background-color: ${getColor('gray', '500')};
        color: ${getColor('gray', '100')};
        border: none;
        cursor: default;
    }
`;

const choosePadding = (size: ButtonSize = 'normal') => {
    switch (size) {
        case 'normal':
            return '0.65rem 0.75rem';
        case 'small':
            return '0.55rem 0.65rem';
    }
};

const chooseFontsize = (size: ButtonSize = 'normal') => {
    switch (size) {
        case 'normal':
            return '0.75rem';
        case 'small':
            return '0.65rem';
    }
};

const chooseBgColor = (
    variant: ButtonVariant = 'primary',
    hover: boolean = false
) => {
    switch (variant) {
        case 'primary':
            return hover ? getColor('pink', '500') : getColor('pink', '700');
        case 'secondary':
            return hover ? getColor('gray', '100') : 'white';
    }
};
