import { CmsSiteData, UserDocument } from '@taltta/cms-data-models';
import { useMemo } from 'react';
import {
    Form,
    H2,
    FieldGroup,
    Label,
    TextInput,
    FieldGroupError,
    Select,
    Button,
} from '@taltta/react-elements';
import { Controller, useForm } from 'react-hook-form';

export type CreateSiteForm = Omit<CmsSiteData, 'pages' | 'draftPages'>;

type CreateSiteFormProps = {
    onSubmit: (data: CmsSiteData) => Promise<void>;
    users: UserDocument[];
};

export function CreateSiteForm(props: CreateSiteFormProps) {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<CreateSiteForm>();

    const options = useMemo(() => {
        return props.users.map((u) => ({
            label: u.email,
            value: u._id,
        }));
    }, [props.users]);
    return (
        <Form
            onSubmit={handleSubmit((data) =>
                props.onSubmit({ ...data, pages: {}, draftPages: {} })
            )}
        >
            <H2>Create a new site</H2>
            <FieldGroup>
                <Label>Name</Label>
                <TextInput
                    {...register('key', {
                        required: {
                            value: true,
                            message: 'Name is required',
                        },
                        minLength: {
                            value: 3,
                            message: 'Name should be atleast 3 charactes long',
                        },
                    })}
                />
                {errors.key && (
                    <FieldGroupError>{errors.key.message}</FieldGroupError>
                )}
            </FieldGroup>
            <FieldGroup>
                <Label>Owner</Label>
                <Controller
                    name="ownerId"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Owner is required',
                        },
                    }}
                    render={({ field }) => (
                        <Select
                            options={options}
                            value={options.find(
                                (op) => op.value === field.value
                            )}
                            onChange={(
                                op: {
                                    label: string;
                                    value: string;
                                } | null
                            ) => field.onChange(op?.value)}
                        />
                    )}
                />
                {errors.ownerId && (
                    <FieldGroupError>{errors.ownerId.message}</FieldGroupError>
                )}
            </FieldGroup>
            <Button type="submit">Create</Button>
        </Form>
    );
}
