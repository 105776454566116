import { useUser } from '../../providers/AuthProvider';
import { Link, useLocation } from 'wouter';
import { useForm } from 'react-hook-form';
import { LoginData } from '@taltta/cms-data-models';
import { AuthLayout } from '../../components/AuthLayout';
import {
    Button,
    Card,
    FieldGroup,
    Form,
    Label,
    TextInput,
} from '@taltta/react-elements';
import { axiosWrapper } from '../../utils/axios-wrapper';

export function LoginPage() {
    const { login } = useUser();
    const { register, handleSubmit } = useForm<LoginData>();
    const [, setLocation] = useLocation();
    const submit = async (data: LoginData) => {
        await axiosWrapper(async () => {
            await login(data.email, data.password);
            setLocation('/');
        });
    };
    return (
        <AuthLayout>
            <div className="w-screen h-screen flex justify-center items-center">
                <Card>
                    <Form className="w-80" onSubmit={handleSubmit(submit)}>
                        <FieldGroup>
                            <Label htmlFor="email">Email</Label>
                            <TextInput
                                {...register('email')}
                                id="email"
                                type="text"
                                placeholder="Email"
                            />
                        </FieldGroup>
                        <FieldGroup>
                            <Label htmlFor="password">Password</Label>
                            <TextInput
                                {...register('password')}
                                id="password"
                                type="password"
                                placeholder="Password"
                            />
                        </FieldGroup>

                        <Button>Login</Button>
                    </Form>
                </Card>
            </div>
        </AuthLayout>
    );
}
