import { createRoot } from 'react-dom/client';
import { SWRConfig } from 'swr';
import { AuthProvider } from './providers/AuthProvider';
import { App } from './App';
import axios from 'axios';
import { WebContainerProvider } from './providers/WebContainerProvider';

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
    <SWRConfig value={{ fetcher }}>
        <AuthProvider>
            <WebContainerProvider>
                <App />
            </WebContainerProvider>
        </AuthProvider>
    </SWRConfig>
);
