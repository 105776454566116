import { createContext, useContext, useEffect, useState } from 'react';
import {
    resetWebContainer,
    webcontainerInitialized,
    webcontainerUrl,
} from '../webcontainer-instance';
import { FileSystemTree } from '@webcontainer/api';
type WebContainerContext = {
    url: string;
    loading: boolean;
    instanceExists: boolean;
    createInstance: (files: FileSystemTree) => Promise<void>;
};

const webcontainerContext = createContext<WebContainerContext>({
    url: '',
    loading: true,
    instanceExists: false,
    createInstance: async () => {},
});

export function WebContainerProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState(webcontainerUrl ?? '');
    const [initialized, setInitialized] = useState(webcontainerInitialized);
    const createInstance = async (files: FileSystemTree) => {
        setLoading(true);
        setInitialized(true);
        await resetWebContainer(files);
        setUrl(webcontainerUrl);
        setLoading(false);
    };

    return (
        <webcontainerContext.Provider
            value={{
                loading,
                url,
                createInstance,
                instanceExists: initialized,
            }}
        >
            {children}
        </webcontainerContext.Provider>
    );
}

export function useWebContainer() {
    return useContext(webcontainerContext);
}
