import { AuthContext, useUser } from '../../providers/AuthProvider';
import { Redirect } from 'wouter';
import { ComponentType } from 'react';
import { AppLayout } from '../AppLayout';

export function BaseGuard<T extends {}>(
    Component: ComponentType<T>,
    shouldRedirect?: (auth: AuthContext) => boolean
): ComponentType<T> {
    const Page = (props: T) => {
        const authContext = useUser();

        if (authContext.loadingUser) {
            return <p>Loading</p>;
        }

        if ((shouldRedirect?.(authContext) ?? false) || !authContext.loggedIn) {
            return <Redirect href="/auth/login" />;
        }

        return (
            <AppLayout>
                <Component {...props} />
            </AppLayout>
        );
    };
    return Page;
}

export type Guard = (Page: ComponentType) => ReturnType<typeof BaseGuard>;
