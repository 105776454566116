import { UserDocument, CreateUserData } from '@taltta/cms-data-models';
import { useMemo } from 'react';
import {
    Form,
    H2,
    FieldGroup,
    Label,
    TextInput,
    FieldGroupError,
    Select,
    Button,
} from '@taltta/react-elements';
import { Controller, useForm } from 'react-hook-form';

export type CreateUserForm = Omit<CreateUserData, 'admin'>;

type CreateUserFormProps = {
    onSubmit: (data: CreateUserData) => Promise<void>;
};

export function CreateUserForm(props: CreateUserFormProps) {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<CreateUserForm>();

    return (
        <Form
            onSubmit={handleSubmit((data) =>
                props.onSubmit({ ...data, admin: false })
            )}
        >
            <H2>Create a new user</H2>
            <FieldGroup>
                <Label htmlFor="name">Name</Label>
                <TextInput
                    {...register('email', {
                        required: {
                            value: true,
                            message: 'Email is required',
                        },
                        minLength: {
                            value: 3,
                            message: 'Email should be atleast 3 charactes long',
                        },
                    })}
                />
                {errors.email && (
                    <FieldGroupError>{errors.email.message}</FieldGroupError>
                )}
            </FieldGroup>
            <FieldGroup>
                <Label htmlFor="password">Password</Label>
                <TextInput
                    {...register('password', {
                        required: {
                            value: true,
                            message: 'Password is required',
                        },
                        minLength: {
                            value: 8,
                            message:
                                'Passwrod should be atleast 8 charactes long',
                        },
                    })}
                    type="password"
                />
                {errors.password && (
                    <FieldGroupError>{errors.password.message}</FieldGroupError>
                )}
            </FieldGroup>
            <Button type="submit">Create</Button>
        </Form>
    );
}
