import { WebContainer, FileSystemTree } from '@webcontainer/api';

// Boot up webcomponents
export let webcontainerInstance: WebContainer;

export let webcontainerUrl: string;

export let webcontainerInitialized: boolean;

export let webcontainerBooting: boolean;

export let webcontainerBooted: boolean = false;

export const spawnWebContainer = async (files: FileSystemTree) => {
    if (webcontainerInitialized || webcontainerBooting) {
        return;
    }
    webcontainerBooting = true;
    if (!webcontainerBooted) {
        webcontainerInstance = await WebContainer.boot();
        webcontainerBooted = true;
    }

    await webcontainerInstance.mount(files);

    const installRes = await webcontainerInstance.spawn('npm', ['install']);

    if ((await installRes?.exit) !== 0) {
        console.log(installRes?.output);
        throw new Error('Installation failed');
    }
    await webcontainerInstance.spawn('npm', ['run', 'dev']);

    await new Promise((resolve) => {
        // Wait for `server-ready` event
        webcontainerInstance.on('server-ready', (port, url) => {
            webcontainerUrl = url;
            resolve(null);
        });
    });
    webcontainerInitialized = true;
    webcontainerBooting = false;
};

export const resetWebContainer = async (files: FileSystemTree) => {
    if (webcontainerBooting) {
        return;
    }
    if (!webcontainerInitialized) {
        await spawnWebContainer(files);
    } else {
        webcontainerInitialized = false;
        await spawnWebContainer(files);
    }
};
