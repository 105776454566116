import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

Form.defaultProps = {
    onSubmit: (e) => {
        e.preventDefault();
    },
};
