import styled from 'styled-components';
import { getColor } from '../colors/colors';

export const FieldGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const FieldGroupError = styled.p`
    font-size: 0.75rem;
    color: ${getColor('red', '900')};
`;
