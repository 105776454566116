import { AxiosResponse } from 'axios';

export const axiosWrapper = async <T extends Record<string, any>>(
    fn: () => Promise<AxiosResponse<T> | undefined | void>
) => {
    try {
        const data = await fn();
        return data?.data;
    } catch (e) {
        console.error(e);
        try {
            alert(JSON.stringify(e.response.data));
        } catch (e) {
            alert(e.message);
        }
    }
};
